import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './styles.scss';

export const query = graphql`
    query PortfolioListingQuery {
        allPortfolioYaml(sort: {fields: order, order: DESC}) {
            nodes {
                id
                slug
                name
                hero {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED,
                            width: 900
                        )
                    }
                }
            }
        }
    }
`;

const PortfolioEntry = ({ slug, name, hero }) => (
    <div className='portfolio-listing-entry'>
        <Link to={'/portfolio/'+slug}>
            <GatsbyImage
                image={getImage(hero)}
                alt={name} />
        </Link>

        <h5><Link to={'/portfolio/'+slug}>{name}</Link></h5>
    </div>
);

export default class PortfolioListing extends React.Component {
    render () {
        return (
            <section className='portfolio-listing'>
                <StaticQuery query={query} render={(props) => props.allPortfolioYaml.nodes.map((node) => (
                    <PortfolioEntry key={node.id} {...node} />
                ))} />
            </section>
        );
    }
}

