import React from 'react';

import Layout from '../components/Layout/';
import PortfolioListing from '../components/PortfolioListing/';

export default class PortfolioPage extends React.Component {
    render () {
        return (
            <Layout title='Portfolio'>
                <article>

                    <header>
                        <h1>Portfolio</h1>
                    </header>

                    <PortfolioListing />

                </article>
            </Layout>
        );
    }
}
